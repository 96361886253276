import { useEffect, useState } from 'react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import WebToolAPI from 'utils/api/WebToolAPI';
import z from 'zod';
import { DropdownField } from '../../forms/DropdownField';

const periodOptions = [
	{ label: 'Monthly', value: 'monthly' },
	{ label: 'Weekly', value: 'weekly' },
];

const dataTypeOptions = [
	{ label: 'Demand', value: 'awb' },
	{ label: 'Capacity', value: 'capacity' },
];

export const zPeriod = z.object({
	period: z.string({
		required_error: 'A publication Period option must be selected',
	}),
});

export type ExecuteWebToolScheduledModalProps = {
	onSubmit?: () => void;
} & Omit<ModalProps, 'title'>;

const ExecuteWebToolScheduledModal = ({
	onSubmit,
	...modalProps
}: ExecuteWebToolScheduledModalProps) => {
	let [periodValue, setPeriodValue] = useState('');
	let [dataTypeValue, setDataTypeValue] = useState('');
	let [filteredPeriodOptions, setFilteredPeriodOptions] =
		useState(periodOptions);
	const handleSubmit = async () => {
		await WebToolAPI.executeReportNotifications(periodValue, dataTypeValue);

		if (onSubmit) {
			onSubmit();
		}
	};

	useEffect(() => {
		if (dataTypeValue === 'capacity') {
			setPeriodValue('monthly');
			setFilteredPeriodOptions(
				periodOptions.filter((opt) => opt.value === 'monthly')
			);
		} else {
			setFilteredPeriodOptions(periodOptions);
		}
	}, [dataTypeValue]);

	return (
		<ConfirmationModal
			fieldName="confirm-distribution"
			title="'On New Data' Schedule"
			submitText="Start 'On New Data' Schedule"
			confirmationText="confirm"
			onSubmit={handleSubmit}
			additionalValidation={zPeriod}
			secondaryChildren={
				<>
					<DropdownField
						isRequired
						label="Data Type"
						contentSource={(option) => option.label}
						options={dataTypeOptions}
						identifierKey="value"
						name="dataType"
						selectedOption={dataTypeOptions.find(
							(opt) => opt.value === dataTypeValue
						)}
						onOptionSelected={(opt) => {
							if (opt) {
								setDataTypeValue(opt.value);
							}
						}}
					/>

					<DropdownField
						isRequired
						label="Publication Period"
						contentSource={(option) => option.label}
						options={filteredPeriodOptions}
						identifierKey="value"
						name="period"
						selectedOption={periodOptions.find(
							(opt) => opt.value === periodValue
						)}
						onOptionSelected={(opt) => {
							if (opt) {
								setPeriodValue(opt.value);
							}
						}}
					/>
				</>
			}
			{...modalProps}
		>
			<p>Are you sure you wish to start schedule?</p>
		</ConfirmationModal>
	);
};

export default ExecuteWebToolScheduledModal;
