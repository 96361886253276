import { createColumnHelper } from '@tanstack/react-table';
import {
	LayoutRouteProps,
	Link,
	LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom';
import BulletIndicator from 'ui/components/BulletIndicator';
import DateFragment from 'ui/components/DateFragment';
import Flex from 'ui/components/Flex';
import MultiStepProgress from 'ui/components/MultiStepProgress';
import { StepStatus } from 'ui/components/MultiStepProgress/MultiStepProgress';
import PageHeader from 'ui/components/PageHeader';
import Table from 'ui/components/Table';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import reportNotificationsAPI, {
	ReportNotificationsDetail,
	ReportNotificationsRecipientItem,
} from '../../api/ReportNotificationsAPI';
import { notificationStatusIntentMap } from '../../util/columns/reportNotificationsColumns';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const report = await reportNotificationsAPI.getReportNotification(
		params.id as string
	);

	return {
		report: report,
	};
};

export function ReportNotificationsDetails() {
	const { report } = useLoaderData() as LoaderData<typeof loader>;

	const reportRecipientColumnHelper =
		createColumnHelper<ReportNotificationsRecipientItem>();

	const reportRecipientColumns = [
		reportRecipientColumnHelper.accessor('user', {
			header: 'User',
			cell: (info) => (
				<Link to={`/users/${info.row.original.userId}`}>{info.getValue()}</Link>
			),
		}),
		reportRecipientColumnHelper.accessor('status', {
			header: 'Status',
		}),
		reportRecipientColumnHelper.accessor('failureMessage', {
			header: 'Message',
			cell: (info) => info.getValue() || '–',
		}),
	];

	const reportScheduleColumnHelper =
		createColumnHelper<ReportNotificationsDetail>();

	const reportDistributionFileColumns = [
		reportScheduleColumnHelper.display({
			header: 'Report/Release Date',
			cell: (info) => {
				const data = info.row.original;
				if (data.reportDate === null || data.reportDate === undefined)
					return (
						<>
							<DateFragment
								includeTime={false}
								date={data.releaseDate}
								timezone="utc"
							/>
						</>
					);
				return (
					<>
						<YearAndMonthFragment value={data.reportDate} />
					</>
				);
			},
		}),
		reportScheduleColumnHelper.accessor('subscriptionNumber', {
			header: 'Subscription Number',
			cell: (info) => (
				<Link to={`/subscriptions/${info.row.original.subscriptionId}`}>
					{info.getValue()}
				</Link>
			),
		}),
		reportScheduleColumnHelper.accessor('report', {
			header: 'Report file',
		}),
	];

	return (
		<>
			<div className="content">
				<PageHeader
					title={
						<Flex direction="column" alignItems="start" gap={12}>
							<span>Report {report.id}</span>
							<BulletIndicator
								intent={notificationStatusIntentMap[report.status]}
								label={report.status}
							/>
						</Flex>
					}
				/>

				<Flex direction="column" gap={20}>
					<MultiStepProgress
						steps={[
							{
								label: 'Created (UTC)',
								status: report.createdAt
									? StepStatus.Completed
									: StepStatus.Pending,
								info: (
									<DateFragment
										date={report.createdAt}
										includeTime
										timezone="utc"
									/>
								),
							},
							{
								label: 'Started (UTC)',
								status: report.startedAt
									? StepStatus.Completed
									: StepStatus.Pending,
								info: (
									<DateFragment
										date={report.startedAt}
										includeTime
										timezone="utc"
									/>
								),
							},
							report.status === 'Failed'
								? {
										label: 'Failed (UTC)',
										status: StepStatus.Error,
										info: (
											<DateFragment
												date={report.completedAt}
												includeTime
												timezone="utc"
											/>
										),
									}
								: {
										label: 'Completed (UTC)',
										status: report.completedAt
											? StepStatus.Completed
											: StepStatus.Pending,
										info: (
											<DateFragment
												date={report.completedAt}
												includeTime
												timezone="utc"
											/>
										),
									},
						]}
					/>

					<Flex direction="column" gap={12}>
						<b>Details</b>
						<Table
							columns={reportDistributionFileColumns}
							data={[report]}
							identifierKey="id"
						/>
					</Flex>

					<Flex direction="column" gap={12}>
						<b>Recipients</b>
						<Table
							columns={reportRecipientColumns}
							data={report.recipients}
							identifierKey="id"
						/>
					</Flex>
				</Flex>
			</div>
		</>
	);
}

export const REPORTS_NOTIFICATIONS_DETAILS_ROUTE: LayoutRouteProps = {
	loader,
	element: <ReportNotificationsDetails />,
	handle: {
		breadcrumbs: { label: 'Details' },
	},
};
