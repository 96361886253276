import { Cdd, CddMonthAreas } from '../../api/DataManagementAPI';
import { YearAndMonthApi } from 'utils/types/helpers';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import NumberFragment from 'ui/components/NumberFragment';
import Pill from 'ui/components/Pill';

export type CddMonitorProps = {
	months: CddMonthAreas[];
	yearsAndMonths: YearAndMonthApi[];
	selectedMonth: YearAndMonthApi;
};

export type CddMonitorOutputProps = {
	cdd: Cdd[];
	yearAndMonth: YearAndMonthApi;
};

export type CddMonitorEntryProps = {
	current: number;
	previous?: number;
	compare?: number;
};

const CddMonitorEntry = ({
	current,
	previous,
	compare,
}: CddMonitorEntryProps) => {
	let abs = compare ? Math.abs(compare) : 0;
	let title = previous ? `Previous ${previous.toLocaleString()}` : '';
	return (
		<>
			<span className={abs > 20 ? 'cdd--error' : ''}>
				<NumberFragment value={current} /> (
				{compare !== undefined && compare !== null ? (
					<span title={title}>
						<NumberFragment value={compare} decimalPlaces={2} suffix="%" />
					</span>
				) : (
					'N/A'
				)}
				)
			</span>
		</>
	);
};

const CddMonitorOutput = ({ cdd, yearAndMonth }: CddMonitorOutputProps) => {
	let match = cdd.find(
		(f) => f.year === yearAndMonth.year && f.month === yearAndMonth.month
	);
	if (match === undefined) {
		return (
			<>
				<td className="table__cell table__cell--section__start"></td>
				<td className="table__cell table__cell--section__start"></td>
			</>
		);
	}
	return (
		<>
			<td className="table__cell table__cell--section__start table__cell--align-right">
				<CddMonitorEntry
					current={match.awbs}
					previous={match.awbsPrevious}
					compare={match.compareAwbs}
				/>
			</td>
			<td className="table__cell table__cell--section__start table__cell table__cell--align-right">
				<CddMonitorEntry
					current={match.segments}
					previous={match.segmentsPrevious}
					compare={match.compareSegments}
				/>
			</td>
		</>
	);
};

const CddMonitor = ({ months, yearsAndMonths }: CddMonitorProps) => {
	return (
		<>
			<div className="table">
				<table className="table__main">
					<thead className="table__header">
						<tr>
							<th rowSpan={2} className="table__column cdd--airline">
								Airline
							</th>
							{yearsAndMonths.map((ym: YearAndMonthApi) => (
								<th
									colSpan={2}
									className="table__cell table__cell--section__start  table__cell--align-center cdd--values"
									key={`${ym.year}-${ym.month}`}
								>
									<YearAndMonthFragment value={ym} />
								</th>
							))}
						</tr>
						<tr>
							{yearsAndMonths.map(() => (
								<>
									<th className="table__cell table__cell--section__start table__cell--align-center">
										AWBs
									</th>
									<th className="table__cell table__cell--section__start table__cell--align-center">
										Segments
									</th>
								</>
							))}
						</tr>
					</thead>
					<tbody className="table__body">
						{months.map((m: CddMonthAreas) => (
							<tr key={m.airlineNaturalKey}>
								<td>
									<Pill code={m.airlineNaturalKey} name={m.airlineName} />
								</td>
								{yearsAndMonths.map((ym: YearAndMonthApi) => (
									<CddMonitorOutput
										key={`${m.airlineNaturalKey}-${ym.year}-${ym.month}`}
										cdd={m.areas}
										yearAndMonth={ym}
									/>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default CddMonitor;
