import { RefreshCcw, Search } from 'lucide-react';
import { useId } from 'react';
import toast from 'react-hot-toast';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import DateField from 'ui/components/DateField';
import ExportButton from 'ui/components/ExportButton';
import { ExportFormat } from 'ui/components/ExportButton/ExportButton';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import WebToolUsageTrackingAPI from '../../api/WebToolUsageTrackingAPI';
import CustomerPicker from '../../components/CustomerPicker';
import SubscriptionPicker from '../../components/SubscriptionPicker';
import UserPicker from '../../components/UserPicker';
import { DropdownField } from '../../forms/DropdownField';
import * as webToolUsageTrackingColumns from '../../util/columns/webToolUsageTrackingColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const response = await WebToolUsageTrackingAPI.getUsageTrackingData(
		new URL(request.url).searchParams
	);

	return {
		trackingData: response.trackingData,
		customersSelected: response.customersSelected,
		usersSelected: response.usersSelected,
		subscriptionsSelected: response.subscriptionsSelected,
		dataTypeSelected: response.dataTypeSelected,
		dataTypeOptions: response.dataTypeOptions,
		runAfterDate: response.runAfterDate,
		runBeforeDate: response.runBeforeDate,
	};
};

async function handleDownloadData(format: string) {
	const toastId = toast.loading('Starting Export...');

	const searchParams = new URLSearchParams(location.search);

	const rt = await WebToolUsageTrackingAPI.beginUsageTrackingDataExport(
		format,
		searchParams
	);

	toast.remove();

	if (rt instanceof Error) {
		toast.error('An error has occurred when starting the export', {
			id: toastId,
		});
	} else {
		toast.success(
			'Export started. The file will be available in System > Admin Exports',
			{ id: toastId }
		);
	}
}

export function UsageTrackingListPage() {
	const formId = useId();
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		webToolUsageTrackingColumns.runTypeColumn,
		webToolUsageTrackingColumns.reportNameColumn,
		webToolUsageTrackingColumns.statusColumn,
		webToolUsageTrackingColumns.executedAtColumn,
		webToolUsageTrackingColumns.runTimeColumn,
		webToolUsageTrackingColumns.userColumn,
		webToolUsageTrackingColumns.subscriptionColumn,
		webToolUsageTrackingColumns.customerColumn,
		webToolUsageTrackingColumns.detailsColumn,
		webToolUsageTrackingColumns.rowCountColumn,
		webToolUsageTrackingColumns.outputFormatColumn,
	];

	const availableFormats: ExportFormat[] = [
		{ id: 'xlsx', name: 'XLSX' },
		{ id: 'csv', name: 'CSV' },
	];
	const webToolProducts = [
		'capacity-web-tool',
		'capacity-web-tool-admin',
		'capacity-web-tool-iata',
		'web-tool-airline',
		'web-tool-freight-forwarder',
		'web-tool-third-party-operational',
		'web-tool-third-party-revenue',
		'web-tool-iata',
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Web Tool Usage Tracking">
					<RevalidationButton>Refresh</RevalidationButton>
					<ExportButton
						onExport={handleDownloadData}
						fallbackFormatId="xlsx"
						availableFormats={availableFormats}
					/>
				</PageHeader>
				<Table
					identifierKey="id"
					columns={columns}
					data={data.trackingData.items}
				/>
				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.trackingData.page}
					pageParameterName="page"
					pageSize={data.trackingData.pageSize}
					itemCount={data.trackingData.totalCount}
				/>
			</div>
			<Sidebar title="Web Tool Usage Tracking Filter">
				<ValidatedForm method="get" id={formId} resetOnNavigation>
					<DateField
						label="Run After"
						name="runAfterDate"
						timezone="utc"
						initialValue={data.runAfterDate}
						includeTime
					/>
					<DateField
						label="Run Before"
						name="runBeforeDate"
						timezone="utc"
						initialValue={data.runBeforeDate}
						includeTime
					/>
					<UserPicker
						name="userIds"
						label="User"
						initialValues={data.usersSelected ? data.usersSelected : []}
						isMulti={true}
					/>
					<SubscriptionPicker
						name="subscriptionIds"
						label="Subscription"
						initialValues={
							data.subscriptionsSelected ? data.subscriptionsSelected : []
						}
						isMulti={true}
						productTypes={webToolProducts}
					/>
					<CustomerPicker
						name="customerIds"
						label="Customer"
						initialValues={data.customersSelected ? data.customersSelected : []}
						isMulti={true}
					/>
					<DropdownField
						label="Data Type"
						name="dataType"
						placeholder="Show all"
						options={data.dataTypeOptions}
						identifierKey="value"
						contentSource={(data) => data.label}
						initialValue={data.dataTypeSelected}
						isClearable
					/>
					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const WEB_TOOL_USAGE_TRACKING_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <UsageTrackingListPage />,
};
