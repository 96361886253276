import { sub } from 'date-fns';
import { RefreshCcw, Search } from 'lucide-react';
import { useId } from 'react';
import toast from 'react-hot-toast';
import { LoaderFunctionArgs, redirect, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import DateField from 'ui/components/DateField';
import ExportButton, {
	ExportFormat,
} from 'ui/components/ExportButton/ExportButton';
import Grid from 'ui/components/Grid';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import WebToolWorksheetAPI from '../../api/WebToolWorksheetAPI';
import CustomerPicker from '../../components/CustomerPicker';
import SubscriptionPicker from '../../components/SubscriptionPicker';
import UserPicker from '../../components/UserPicker';
import { DropdownField } from '../../forms/DropdownField';
import * as webToolReportColumns from '../../util/columns/webToolReportsColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	let searchParams = new URL(request.url).searchParams;

	if (
		!searchParams.has('createdAfterDate') &&
		[...searchParams.keys()].length === 0
	) {
		const defaultCreatedAfter = sub(new Date(), { months: 1 }).toISOString();
		searchParams.set('createdAfterDate', defaultCreatedAfter);
		return redirect(`/web-tool-reports?${searchParams.toString()}`);
	}

	return await WebToolWorksheetAPI.getWorksheets(searchParams);
};

async function handleDownloadData(format: string) {
	const toastId = toast.loading('Starting Export...');

	const searchParams = new URLSearchParams(location.search);

	const rt = await WebToolWorksheetAPI.beginAdminWorksheetExport(
		format,
		searchParams
	);

	toast.remove();

	if (rt instanceof Error) {
		toast.error('An error has occurred when starting the export', {
			id: toastId,
		});
	} else {
		toast.success(
			'Export started. The file will be available in System > Admin Exports',
			{ id: toastId }
		);
	}
}

export function ReportsListPage() {
	const formId = useId();
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		webToolReportColumns.nameColumn,
		webToolReportColumns.idColumn,
		webToolReportColumns.userColumn,
		webToolReportColumns.subscriptionColumn,
		webToolReportColumns.customerColumn,
		webToolReportColumns.createdAtColumn,
		webToolReportColumns.updatedAtColumn,
	];
	const availableFormats: ExportFormat[] = [
		{ id: 'xlsx', name: 'XLSX' },
		{ id: 'csv', name: 'CSV' },
	];
	const webToolProducts = [
		'capacity-web-tool',
		'capacity-web-tool-admin',
		'capacity-web-tool-iata',
		'web-tool-airline',
		'web-tool-freight-forwarder',
		'web-tool-third-party-operational',
		'web-tool-third-party-revenue',
		'web-tool-iata',
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Web Tool Reports">
					<RevalidationButton>Refresh</RevalidationButton>
					<ExportButton
						onExport={handleDownloadData}
						fallbackFormatId="xlsx"
						availableFormats={availableFormats}
					/>
				</PageHeader>

				<Table
					identifierKey="id"
					columns={columns}
					data={data.worksheets.items}
				/>
				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.worksheets.page}
					pageParameterName="page"
					pageSize={data.worksheets.pageSize}
					itemCount={data.worksheets.totalCount}
				/>
			</div>
			<Sidebar title="Web Tool Report Filter">
				<ValidatedForm method="get" id={formId} resetOnNavigation>
					<TextField label="Report Id" name="id" />
					<TextField label="Report Name" name="name" />
					<UserPicker
						name="userIds"
						label="User"
						initialValues={data.usersSelected ? data.usersSelected : []}
						isMulti={true}
					/>
					<SubscriptionPicker
						name="subscriptionIds"
						label="Subscription"
						initialValues={
							data.subscriptionsSelected ? data.subscriptionsSelected : []
						}
						isMulti={true}
						productTypes={webToolProducts}
					/>
					<CustomerPicker
						name="customerIds"
						label="Customer"
						initialValues={data.customersSelected ? data.customersSelected : []}
						isMulti={true}
					/>
					<Grid columns={2}>
						<DateField
							label="Created After"
							name="createdAfterDate"
							initialValue={data.createdAfterDate}
							timezone="utc"
						/>
						<DateField
							label="Created Before"
							name="createdBeforeDate"
							initialValue={data.createdBeforeDate}
							timezone="utc"
						/>
					</Grid>
					<MultiSelectField
						label="Types of Report"
						name="reportTypes"
						options={data.reportTypeOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
					/>
					<DropdownField
						label="Data Type"
						name="dataType"
						placeholder="Show all"
						options={data.dataTypeOptions}
						identifierKey="value"
						contentSource={(data) => data.label}
						initialValue={data.dataTypeSelected}
						isClearable
					/>
					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}
export const WEB_TOOL_REPORTS_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <ReportsListPage />,
};
