import { RefreshCcw, Search, UploadIcon } from 'lucide-react';
import React, { useCallback } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import AirlinePicker from 'ui/components/AirlinePicker';
import Button from 'ui/components/Button/Button';
import DateField from 'ui/components/DateField';
import Flex from 'ui/components/Flex/Flex';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import CddContributorAPI, { CddErrorCode } from 'utils/api/CddContributorAPI';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import { DropdownField } from '../../forms/DropdownField';
import * as cddContributorColumns from '../../util/columns/cddContributorColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	return await CddContributorAPI.getCddContributorAdminList(
		new URL(request.url).searchParams
	);
};

export function CddContributorAdminListPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const codeDescriptions = React.useMemo(() => {
		const codesMap: Record<string, CddErrorCode> = {};
		for (const errorCode of data.errorCodes) {
			codesMap[errorCode.code] = errorCode;
		}
		return codesMap;
	}, [data.errorCodes]);

	const columns = React.useMemo(
		() => [
			cddContributorColumns.contributorCodeOrPrefixColumn,
			cddContributorColumns.externalFileColumn,
			cddContributorColumns.receivedAtColumn,
			cddContributorColumns.statusColumn(codeDescriptions),
			cddContributorColumns.statusContributorColumn,
			cddContributorColumns.startedAtColumn,
			cddContributorColumns.completedAtColumn,
			cddContributorColumns.rowCountColumn,
			cddContributorColumns.downloadReportColumn,
		],
		[codeDescriptions]
	);
	useCallback((status: string) => {
		switch (status.toLocaleLowerCase()) {
			case 'publishing':
				return 'success';
			case 'publishing-paused':
				return 'warning';
			case 'data-testing':
			default:
				return 'info';
		}
	}, []);
	return (
		<>
			<Sidebar.Wrapper>
				<div className="content">
					<PageHeader
						title={
							<Flex direction="column" gap={12}>
								<span>CDD / NDD Submissions</span>
							</Flex>
						}
					>
						<RevalidationButton>Refresh</RevalidationButton>
						<Button
							variant="secondary"
							icon={UploadIcon}
							to={`/cdd-contributor/upload`}
						>
							Upload
						</Button>
					</PageHeader>

					<Grid columns={undefined}>
						<Flex direction="column" gap={16}>
							<Table
								columns={columns}
								data={data.submissions.items}
								identifierKey="jobId"
								emptyText="No submissions found."
							/>
							<Pagination
								baseUrl={new URL(window.location.href)}
								page={data.submissions.page}
								pageParameterName="page"
								pageSize={data.submissions.pageSize}
								itemCount={data.submissions.totalCount}
							/>
						</Flex>
					</Grid>
				</div>
				<Sidebar title="Contribution Filter">
					<ValidatedForm method="get" resetOnNavigation>
						<Grid columns={2}>
							<DateField
								label="Received From"
								name="receivedDateStart"
								isRequired={false}
								initialValue={data.receivedDateStart}
								timezone="utc"
							/>
							<DateField
								label="Received To"
								name="receivedDateEnd"
								isRequired={false}
								initialValue={data.receivedDateEnd}
								timezone="utc"
							/>
						</Grid>
						<Grid columns={2}>
							<DateField
								label="Started From"
								name="startedDateStart"
								isRequired={false}
								initialValue={data.startedDateStart}
								timezone="utc"
							/>
							<DateField
								label="Started To"
								name="startedDateEnd"
								isRequired={false}
								initialValue={data.startedDateEnd}
								timezone="utc"
							/>
						</Grid>
						<DropdownField
							identifierKey="value"
							name="status"
							label="Status"
							placeholder="Show all"
							options={data.statusCodes}
							contentSource={(data) => data.label}
						/>
						<AirlinePicker
							name="airlineCodes"
							label="Airlines"
							isMulti={true}
						/>
						<TextField
							name="codeOrPrefix"
							label="GSA / NDD Code"
							minLength={3}
							maxLength={4}
						/>

						<Sidebar.Actions>
							<Button variant="secondary" icon={RefreshCcw} type="reset">
								Clear search
							</Button>
							<Button variant="primary" icon={Search} type="submit">
								Search
							</Button>
						</Sidebar.Actions>
					</ValidatedForm>
				</Sidebar>
			</Sidebar.Wrapper>
		</>
	);
}

export const LIST_CDD_CONTRIBUTOR_ADMIN_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <CddContributorAdminListPage />,
};
