import { createColumnHelper } from '@tanstack/react-table';
import { ENVIRONMENT_SHARED } from 'environment';
import { Link as LinkIcon, Package, Plane } from 'lucide-react';
import { Link } from 'react-router-dom';
import Badge from 'ui/components/Badge/Badge';
import CopyButton from 'ui/components/CopyButton/CopyButton';
import DateFragment from 'ui/components/DateFragment';
import Flex from 'ui/components/Flex/Flex';
import { WorksheetRow } from '../../api/WebToolWorksheetAPI';

const columnHelper = createColumnHelper<WorksheetRow>();

function getBadgeText(row: WorksheetRow) {
	if (row.supportWorksheet) {
		return 'Support';
	} else if (row.sharedWorksheet && row.modifiedSharedWorksheet) {
		return 'Shared - Modified';
	} else if (row.sharedWorksheet && !row.modifiedSharedWorksheet) {
		return 'Shared';
	}
}

export const nameColumn = columnHelper.accessor('name', {
	header: 'Report Name',
	cell: (info) => {
		let badgeText = getBadgeText(info.row.original);
		const url = `${ENVIRONMENT_SHARED.portalUrl}/webtool/${info.row.original.id}`;
		const Icon = info.row.original.reportDataType === 'awb' ? Package : Plane;

		return (
			<Flex className="copy-group" alignItems="center" gap={8}>
				<Icon size={14} />
				{info.getValue()} {badgeText && <Badge size="sm">{badgeText}</Badge>}
				<CopyButton copyText={url} size={12} icon={LinkIcon} />
			</Flex>
		);
	},
	enableSorting: true,
});

export const idColumn = columnHelper.accessor('id', {
	header: 'Report ID',
	cell: (info) => info.getValue(),
});

export const subscriptionColumn = columnHelper.accessor('subscriptionName', {
	header: 'Subscription',
	cell: (info) => (
		<Link to={`/subscriptions/${info.row.original.subscriptionId}`}>
			{info.row.original.subscriptionName || info.row.original.productName} (
			{info.row.original.subscriptionNumber})
		</Link>
	),
});

export const customerColumn = columnHelper.accessor('customerName', {
	header: 'Customer',
	cell: (info) => (
		<Link to={`/customers/${info.row.original.customerId}`}>
			{info.getValue()} ({info.row.original.customerNumber})
		</Link>
	),
});

export const userColumn = columnHelper.accessor('user', {
	header: 'User',
	cell: (info) => (
		<Link to={`/users/${info.row.original.userId}`}>{info.getValue()}</Link>
	),
});

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date/Time (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});

export const updatedAtColumn = columnHelper.accessor('updatedAt', {
	header: 'Updated Date/Time (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});
